.not_found {
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    left: 0;
    z-index: -1;
  }

  display: flex;
  flex-direction: column;

  .logo_wrapper {
    padding: 30px 0;
    box-sizing: border-box;
  }

  &_content_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
    }
    .cta {
      padding: 18px 24px;
      border-radius: 50px;
      background: $blue_color;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white_text_color;
      font-family: $Arial-400;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin: 20px 0 0;
      line-height: 16px;
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background: $white_text_color;
        color: $black_text_color;
        border-color: $blue_color;
      }
    }
  }
}
