.result_wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  padding: 54px 72px 54px 36px;
  box-sizing: border-box;
  margin-bottom: 68px;
  @media all and (max-width: 767px) {
    padding: 54px 32px 40px;
    margin-bottom: 32px;
  }


  &.success {
    &:after {
      content: '';
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: linear-gradient(138deg, #D4FFED 4.73%, #FCFEBB 86.93%);
    }
  }

  &.error {
    &:after {
      content: '';
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: linear-gradient(138deg, #FFD7D4 4.73%, #FCFEBB 86.93%);
    }
  }

  &_body {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    .body_img {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      @media all and (max-width: 767px) {
        margin: 0 0 40px;
      }

      img {
        display: block;
        width: 100%;
        max-width: 140px;
        height: auto;
      }
    }

    .body_data {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $black_text_color;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;

      h2 {
        font-family: $Arial-700;
        font-weight: 700;
        margin: 0 0 32px;
        @media all and (max-width: 767px) {
          text-align: center;
        }
      }

      p {
        font-family: $Arial-400;
        font-weight: 400;
        margin: 0;
        @media all and (max-width: 767px) {
          text-align: center;
        }
      }
    }
  }
}