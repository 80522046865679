.auth_header {
  padding: 30px 0;
  @media all and (max-width: 767px) {
    padding: 20px 0 40px;
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo_wrapper {
    padding-left: 34px;
    a {
      position: relative;
      display: block;
      &:before {
        content: 'Beta';
        color: #3366FF;
        font-size: 12px;
        position: absolute;
        top: 0;
        left: -31px;
        font-family: Arial-700, sans-serif;
      }
    }
    display: block;
    width: 100%;
    max-width: 168px;
    @media all and (max-width: 767px) {
      max-width: 130px;
    }

    svg {
      display: block;
      width: 100%;
    }
  }

  .home_link {
    color: #191919;
    text-decoration: none;
    margin: 0 20px 0 auto;
    font-family: $Arial-400;
    transition: all .3s ease-in-out;

    &:hover {
      transition: all .3s ease-in-out;
      color: $blue_color;
    }
  }

  .menu {
    cursor: pointer;
    position: relative;
    padding: 4px;
    border-radius: 30px;
    box-sizing: border-box;
    //border: 1px solid #fff;
    display: flex;
    align-items: center;
    background: #fff;

    &_hidden_wrapper {
      padding-top: 20px;
      position: absolute;
      opacity: 0;
      z-index: -1;
      right: 0;
      top: calc(100%);
    }

    &_initials {
      text-transform: uppercase;

      display: flex;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 50%;
      background: $blue_color;
      color: #fff;
      font-family: $Arial-400;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }

    .fa-caret-down {
      margin-right: 6px;
      font-size: 10px;
      color: #191919;
      transition: all .3s ease-in-out;
      transform: translateY(0);
    }

    svg {
      display: block;
      margin-right: 4px;
      width: 24px;
      height: auto;
    }

    &:hover {
      .fa-caret-down {
        transition: all .3s ease-in-out;
        transform: translateY(3px);
      }

      .menu_hidden_wrapper {
        display: flex;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        z-index: 1;
      }
    }

    &_hidden {
      transition: all 0.3s ease-in-out;

      padding: 8px 4px;
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
      margin: 0;
      border-radius: 6px;
      background-color: #fff;
      border: 1px solid rgba(25, 25, 25, 0.24);
      list-style: none;
      box-sizing: border-box;
      min-width: 230px;

      li {
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 4px;
        color: #191919;

        &:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 6px;
          margin-bottom: 10px;
          border-bottom: 1px solid rgba(25, 25, 25, 0.24);

          span {
            font-family: $Arial-700;
          }

          i {
            padding: 0 8px;
            cursor: pointer;
            color: #191919;
            transition: all .3s ease-in-out;

            &:hover {
              transition: all .3s ease-in-out;
              color: $blue_color;
            }
          }

          &:hover {
            cursor: initial;

            span {
              background: none;
            }
          }
        }

        &:last-child {
          margin-bottom: 4px;

          span {
            padding: 0 8px;
          }
        }

        span {
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: space-between;
          padding: 4px 8px;
          box-sizing: border-box;
          border-radius: 4px;
          color: $black_text_color;
        }
      }

      .edit_button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        i {
          margin-left: 6px;
        }
      }
    }
  }

  .lang_list {
    position: relative;

    .full_width {
      backdrop-filter: none;
      background: #fff;
      transform-origin: top;
      z-index: 1;
      transform: scaleY(0);
      position: absolute;
      right: 0;
      top: 50px;
      width: 250vw;
      left: -1500px;
      height: 170px;
      transition: all 0.3s ease-in-out;
      @media all and (max-width: 1023px) {
        top: 38px;
      }
      @media all and (max-width: 767px) {
        left: -900%;
      }
    }

    &:hover {
      .full_width {
        transform: scaleY(1);
        transition: all 0.3s ease-in-out;
      }

      .lang_hidden_wrapper {
        transform: scaleY(1);
        transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;
        opacity: 1;
      }

      .lang_current:after {
        transition: opacity 0.2s ease-in-out;
        opacity: 1;

      }
    }

    .href_lang {
      span {
        display: flex;
        align-items: center;
      }
    }

    .lang_current {
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        width: calc(100% - 40px);
        bottom: -16px;
        height: 3px;
        opacity: 0;
        left: 50%;
        transform: translateX(-50%);
        background: $blue_color;
        transition: opacity 0.2s ease-in-out;
        @media all and (max-width: 1023px) {
          bottom: -8px;
        }
        @media all and (max-width: 768px) {
          display: none;
        }
      }

      .curr_img {
        margin-right: 12px;
        position: relative;

        path {
          transition: all 0.3s ease-in-out;
        }
      }

      .lang_title {
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        display: block;
        text-transform: capitalize;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .lang_hidden_wrapper {
      display: flex;
      top: 100%;
      padding: 60px 0 20px;
      position: absolute;
      transform: scaleY(0);
      transform-origin: top;
      box-sizing: border-box;
      height: 190px;
      right: -100px;
      width: 540px;
      z-index: 2;
      opacity: 0;
      transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;


      @media all and (max-width: 767px) {
        width: calc(100vw - 32px);
        right: 0;
        padding: 44px 0 20px;
      }

      ol {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-end;
      }

      li {
        margin-bottom: 6px;
        width: 180px;

        a {
          white-space: nowrap;
          color: $black_text_color;
          font-size: 16px;
          text-decoration: none;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          display: flex;
          align-items: center;
          position: relative;


          .inner_arrow {
            transform: translateX(0);
            opacity: 0;
            transition: all 0.3s ease-in-out;
            @media all and (max-width: 768px) {
              display: none;
            }

            path {
              stroke: $black_text_color;
              transition: all 0.3s ease-in-out;

            }

          }

          &:hover {
            text-decoration: underline;

            .inner_arrow {
              transform: translateX(30px);
              opacity: 1;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }

    }
  }


}
