.main_button {
  padding: 18px 26px;
  border: 1px solid;
  color: $white_text_color;
  font-family: $Arial-400;
  letter-spacing: 0.6px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 50px;
  white-space: nowrap;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media all and (max-width: 1200px) {
    font-size: 16px;
    padding: 10px 26px;
  }

  &.back {
    margin: 0 20px 0 0;

    svg {
      margin-right: 24px;
      margin-left: 0;
    }
  }

  svg {
    margin-left: 24px;
    display: block;

    path {
      transition: all 0.3s ease-in-out;
    }
  }

  &.transparent {
    background: transparent;

    &:hover {
      transition: all 0.3s ease-in-out;
      border-color: $blue_color;
    }
  }

  &.blue {
    &.black {
      background: $black_text_color;
      border-color: $black_text_color;

      &:hover {
        transition: all 0.3s ease-in-out;
        background: $white_text_color;
        color: $black_text_color;

        svg {
          path {
            stroke: $black_text_color;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    background: $blue_color;
    border-color: $blue_color;

    &.blue.dark_bg {
      &:hover {
        transition: all 0.3s ease-in-out;
        background: $white_text_color;
        color: $black_text_color;
        border-color: $white_text_color;


        svg {
          path {
            stroke: $black_text_color;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    &:hover {
      transition: all 0.3s ease-in-out;
      background: $black_text_color;
      color: $white_text_color;
      border-color: $black_text_color;


      svg {
        path {
          stroke: $white_text_color;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    &:disabled:hover {
      background: $blue_color;
      border-color: $blue_color;


      svg {
        path {
          stroke: $white_text_color;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

}

.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1035;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);

  .loader-track {
    position: relative;
    height: 3px;
    display: block;
    width: 100%;
    overflow: hidden;

    .loader-fill {
      &:after,
      &:before {
        content: '';
        background: $theme-color;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
      }

      &:before {
        animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      }

      &:after {
        animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
      }
    }
  }
}

@keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.confirm_button {
  display: inline-flex;
  cursor: pointer;
  border-radius: 4px;
  background: #28a745;
  border: 1px solid #28a745;
  padding: 6px 12px;
  color: #fff;
  font-size: 12px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background: #218838;
    border-color: #1e7e34;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  &:disabled {
    background: #218838;
    border-color: #1e7e34;
    cursor: not-allowed;
  }
}

.secondary_button {
  display: inline-flex;
  cursor: pointer;
  border-radius: 4px;
  background: #6c757d;
  border: 1px solid #6c757d;
  padding: 6px 12px;
  color: #fff;
  font-size: 12px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background: #5a6268;
    border-color: #5a6268;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
}

.edit_button {
  display: inline-flex;
  cursor: pointer;
  border-radius: 4px;
  background: #007bff;
  border: 1px solid #007bff;
  padding: 6px 12px;
  color: #fff;
  font-size: 12px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:disabled {
    background: #0069d9;
    border-color: #0062cc;
    cursor: not-allowed;
  }

  &:hover {
    background: #0069d9;
    border-color: #0062cc;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
}