.auth_platforms {
  display: flex;
  flex-direction: column;
  margin-bottom: 68px;

  .platform {
    display: flex;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-bottom-color: rgba(25, 25, 25, 0.24);
    cursor: pointer;
    @media all and (max-width: 767px){
      flex-direction: column;
    }

    &.MT5 {
      cursor: initial;
    }

    &.selected {
      border: 1px solid $blue_color;
    }

    &_left {
      width: 50%;
      display: flex;
      padding: 40px 20px 20px 0;
      align-items: flex-start;
      box-sizing: border-box;
      @media all and (max-width: 767px){
        width: 100%;
        padding: 40px 0 46px;

      }

      &_icon {
        width: 48px;
        display: block;
        height: auto;
        margin: 0 18px 0 0;
      }

      &_data {
        h2 {
          color: #191919;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-700;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin: 4px 0 6px;
        }

        p {
          color: #191919;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
          @media all and (max-width: 767px){
            max-width: 270px;
          }
        }

        button {
          margin-top: 20px;
          font-family: $Arial-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          color: $white_text_color;
          padding: 4px 24px;
          background: $blue_color;
          border-radius: 50px;
          border: 1px solid transparent;
          margin-right: 20px;
          cursor: initial;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    &_img {
      width: 50%;
      @media all and (max-width: 767px){
        width: 100%;
        max-height: 350px;
      }
      @media all and (max-width: 576px){
        max-height: none;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
  }
}
