.review_wrapper {
  margin: 0 -20px 68px;
  display: flex;

  @media all and (max-width: 1023px) {
    margin: 0 -14px 68px;
  }

  @media all and (max-width: 767px) {
    margin: 0 -14px 32px;
    flex-direction: column;
  }

  .review_item {
    flex: 1;
    padding: 0 20px;

    @media all and (max-width: 1023px) {
      padding: 0 14px;
    }

    &:first-child {
      @media all and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }


    &_bg {
      background: $blue_color_06;
      border-radius: 10px;

    }

    &_title {
      padding: 20px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(25, 25, 25, 0.24);
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }

    &_data {
      padding: 20px;
      box-sizing: border-box;

      &_item {
        display: flex;
        align-items: center;

        .title {
          margin: 0;
          width: 50%;
          color: $black_text_color_50;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
        }

        .value {
          width: 50%;

          .not_editable {
            padding: 4px 8px;
            box-sizing: border-box;
            color: $black_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Arial-400;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;

          }

          input {
            padding: 4px 8px;
            box-sizing: border-box;
            width: 100%;
            color: $black_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Arial-400;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            border: 1px solid rgba(25, 25, 25, 0.24);
            border-radius: 4px;
            background: transparent;

            &:focus {
              outline: none;
            }

            &:disabled {
              border: 1px solid transparent;
            }
          }
        }
      }
    }
  }
}