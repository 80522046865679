/**  =====================
      Custom css start
==========================  **/

@font-face {
  font-family: 'Kameron-400';
  src: url("/assets/fonts/datta/fonts/Kameron/static/Kameron-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: 'Kameron-500';
  src: url("/assets/fonts/datta/fonts/Kameron/static/Kameron-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kameron-600';
  src: url("/assets/fonts/datta/fonts/Kameron/static/Kameron-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kameron-700';
  src: url("/assets/fonts/datta/fonts/Kameron/static/Kameron-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial-400';
  src: url("/assets/fonts/datta/fonts/ArialNew/ARIALLGT.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial-700';
  src: url("/assets/fonts/datta/fonts/Arial/Arial-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.toast-notification {
  width: 100%!important;
  max-width: 600px;
  @media all and (max-width: 576px) {
    max-width: 350px;
  }
}


.container {
  max-width: 1400px;
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
  box-sizing: border-box;
  @media all and (max-width: 1023px) {
    padding: 0 24px;
    max-width: 808px;
  }
  @media all and (max-width: 767px) {
    padding: 0 16px;
  }
}
