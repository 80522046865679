.sign_in_form_data {
  display: flex;
  flex-direction: column;
  width: 100%;

  .link_text {
    margin-bottom: 22px;
  }

  .cta {
    margin-bottom: 56px;
  }

  .links {
    .link_text {
      margin: 0;
      white-space: nowrap;
    }
  }

}