.account_types_list {
  display: flex;
  margin: 0 -10px 68px;
  padding: 0;

  @media all and (max-width: 1023px) {
    margin: 0 -8px 68px;
  }

  @media all and (max-width: 767px) {
    margin: 0 0 32px;
  }
  @media all and (max-width: 767px) {
    flex-direction: column;
  }

  .account {
    transform: scale(1);
    transition: all 1.3s ease-in-out;
    margin: 0 10px;
    padding: 22px;
    border-radius: 20px;
    border: 1px solid rgba(25, 25, 25, 0.12);
    background: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 1023px) {
      padding: 16px;
      margin: 0 8px;
    }

    @media all and (max-width: 767px) {
      margin: 0 0 16px;
    }

    &:last-child {
      @media all and (max-width: 767px) {
        margin: 0;
      }
    }


    &_status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;

      .account_type {
        background: rgba(25, 25, 25, 0.02);
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &_logo {
          display: block;
          width: 48px;
          height: auto;
          margin: 0 12px 0 0;
          @media all and (max-width: 1023px) {
            width: 30px;
          }
          @media all and (max-width: 767px) {
            width: 48px;
          }
        }

        &_info {
          display: flex;
          flex-direction: column;

          h3 {
            color: $black_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Arial-700;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            margin: 0 0 2px;
            white-space: nowrap;
            @media all and (max-width: 1023px) {
              text-align: left;
              font-size: 12px;
              line-height: 14px;
            }

            @media all and (max-width: 767px) {
              font-size: 16px;
              line-height: 22px;
            }
          }

          span {
            padding: 2px 12px;
            box-sizing: border-box;
            display: block;
            border-radius: 16px;
            background: linear-gradient(143deg, #D4FFED -3.31%, #FCFEBB 78.05%);
            color: $black_text_color;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Arial-400;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            align-self: flex-start;
            @media all and (max-width: 1023px) {
              line-height: 14px;
            }
            @media all and (max-width: 767px) {
              line-height: 20px;
            }
          }
        }
      }

      @media all and (max-width: 1023px) {
        margin-bottom: 30px;
      }

      &_title {
        color: $black_text_color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 16px;
        margin: 0;
        @media all and (max-width: 1023px) {
          font-size: 14px;
        }
      }

      &_bonus {
        display: block;
        border-radius: 16px;
        background: linear-gradient(143deg, #D4FFED -3.31%, #FCFEBB 78.05%);
        color: $black_color;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding: 6px 18px;
        box-sizing: border-box;
        @media all and (max-width: 1023px) {
          font-size: 12px;
        }
      }
    }

    &_range_title {
      display: block;
      color: $black_text_color;
      opacity: 0.5;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    &_range_amount {
      display: block;
      color: $black_text_color;
      font-family: $Arial-700;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      padding-bottom: 14px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(25, 25, 25, 0.24);
      margin-bottom: 14px;
      @media all and (max-width: 1023px) {
        font-size: 24px;
        padding-bottom: 14px;
        margin-bottom: 14px;
      }
    }

    &_features {
      padding: 0 0 0 42px;
      box-sizing: border-box;
      margin-bottom: 30px;
      list-style: none;
      @media all and (max-width: 1023px) {
        padding: 0 0 0 16px;
      }

      &_item {
        position: relative;
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        @media all and (max-width: 1023px) {
          font-size: 12px;
          line-height: 18px;
        }

        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -42px;
          width: 12px;
          height: 9px;
          display: block;
          @media all and (max-width: 1023px) {
            left: -16px;
          }
        }
      }
    }

    &.selected {
      transition: all 1.3s ease-in-out;
      border: 1px solid $blue_color;
      transform: scale(1.05);
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
      @media all and (max-width: 767px) {
        transform: scale(1);
      }
    }

    &.Titanium {
      .account_features {
        &_item {
          &:nth-child(5) {
            margin-bottom: 20px;
            @media all and (max-width: 1023px) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    &.Platinum {
      .account_features {
        &_item {
          &:nth-child(5) {
            margin-bottom: 20px;
            @media all and (max-width: 1023px) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    &_cta {
      display: flex;
      text-decoration: none;
      margin: auto 0 0;

      button {
        width: 100%;
        padding: 4px 20px;
        background: rgba(25, 25, 25, 0.08);
        border: none;
        color: $black_text_color;
        font-size: 16px;
        line-height: 28px;

        &.selected {
          background: $blue_color;
          color: $white_text_color;
        }

        svg {
          margin-left: 12px;
        }
      }
    }
  }
}
