.ReactTable {
  z-index: 0;
  background: transparent;
  font-size: 10px;
  font-weight: 400;
  border: none !important;

  .rt-resizable-header-content {
    padding: 0 !important;

    .rt_custom_header {
      display: block;

      &.t-e {
        text-align: right;
      }

      &.t-c {
        text-align: center;
      }

      &.t-s {
        text-align: left;
      }
    }
  }

  .rt-thead.-header {
    box-shadow: none !important;
    border: 1px solid #2b2b2b;
  }

  padding: 4px;

  .rt-tbody {
    .rt-tr-group {
      border: none!important;
      &:nth-child(2n+1) {
        background: #1e1e1e;
      }
      //border-bottom: 1px solid red  !important;

      .rt-td {
        overflow: hidden;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .rt-table {
    overflow-x: scroll !important;
  }

  .rt-td {
    height: 32px;
  }

  .rt-td {
    display: flex;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  .rt-thead.-header {
    //border-top: none !important;
    .rt-thead {
      .rt-th {
        min-height: 40px;
      }
    }
  }

  .rt-thead {
    border-bottom: 1px solid var(--light-gray-bg) !important;
    .rt-th {
      color: rgba(255, 255, 255, .5)!important;
      font-size: 12px!important;
    }
    .rt-resizable-header-content {
      white-space: initial !important;
    }
  }

  .rt-thead.-filters {
    border: 1px solid #2b2b2b !important;
    //border-top: none!important;
    padding: 2px 0;

    .rt-th {
      border-right: 1px solid #2b2b2b !important;

      &:last-child {
        border-right: none !important;
      }

    }

    input, select {
      padding: 2px 6px !important;
      border-color: #2b2b2b !important;
      color: #fff !important;
      background: linear-gradient(180deg, rgba(171, 206, 249, 0.01) 0%, rgba(171, 206, 249, 0.05) 100%) !important;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      height: 22px;
    }
  }

  .rt-resizable-header-content {
    padding: 0 10px;
    text-align: center;
    line-height: 2;
  }

  .rt-th.-sort-asc {
    position: relative;
    box-shadow: inset 0 3px 0 0 #3366ff!important;
  }

  .rt-th.-sort-desc {
    position: relative;
    box-shadow: inset 0 -3px 0 0 #3366ff!important;
  }

  .rt-th {
    font-weight: 600;
    border-right: 1px solid #2b2b2b !important;

    &:last-child {
      border-right: none !important;
    }
  }

  .rt-tr-group {
    border-color: var(--light-gray-bg) !important;
  }

  .-pagination {
    input, select {
      background: linear-gradient(180deg, rgba(171, 206, 249, 0.01) 0%, rgba(171, 206, 249, 0.05) 100%) !important;
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      border-color: #2b2b2b !important;
      color: #fff !important;
    }
  }

  .pagination-bottom {
    .-pagination {
      -webkit-box-shadow: 0px 1px 6px 0px #2b2b2b;
      box-shadow: 0px 1px 6px 0px #2b2b2b !important;
      border-top: 0;

      .-btn {
        border: 1px solid #2b2b2b;
        background: rgba(0, 0, 0, 0.1);
        color: #fff;
      }
    }
  }

  .rt-noData {
    padding: 0 !important;
  }

  .-loading {
    background: #191919a3!important;
    &.-active {
      background: #191919a3;

      .-loading-inner {
        color: $blue_color;
      }
    }
  }
}

