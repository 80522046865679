.form_section {
  display: flex;
  justify-content: center;

  .form_wrapper {
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(25, 25, 25, 0.12);
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(51, 102, 255, 0.08);
    padding: 48px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 130px;
    @media all and (max-width: 767px) {
      padding: 32px 16px;
    }

    &.sign_in {
      max-width: 400px;

      .form_title {
        max-width: 260px;
      }
    }

    &.sign_up {
      max-width: 920px;
      align-items: flex-start;

      .form_title {
        text-align: left;
      }
    }

    .form_title {
      color: #000;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Arial-700;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin: 0 0 40px;
      @media all and (max-width: 1023px){
        margin: 0 0 30px;
        line-height: 28px;

      }
    }
  }

  .input_wrapper {
    &.m-b-40 {
      margin-bottom: 40px;
    }

    &.first_checkbox {
      margin-top: 46px;
      @media all and (max-width: 767px) {
        margin-top: 26px;
      }
    }

    .custom-checkbox {
      position: relative;
      display: block;

      &.required:after {
        content: '*';
        position: absolute;
        right: 0;
        top: 0;
      }

      .label {
        display: block;
        position: relative;
        padding-left: 40px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: $black_text_color_50;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        a {
          color: $black_text_color_50;
        }

        &:hover input ~ .checkmark {
          transition: all 0.3s ease-in-out;
          border-color: $blue_color;
          background-color: $blue_color;
        }

        & input:checked ~ .checkmark {
          background: $blue_color;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark {
            border-color: $blue_color;

            &:after {
              display: block;
            }
          }
        }

        & .checkmark:after {
          content: '';
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .checkmark {
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 6px;
        border: 1px solid $black_text_color_50;
        background-color: transparent;

        &:after {
          display: none;
          content: '';
          position: absolute;
        }
      }
    }

    &.m-0 {
      margin: 0;
    }

    &.mb-6 {
      margin: 0 0 6px;
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    position: relative;

    label {
      color: $black_text_color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Arial-400;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }

    .pass_input {
      position: relative;
      width: 100%;
    }

    input {
      width: 100%;
      border-radius: 10px;
      border: 1px solid rgba(25, 25, 25, 0.24);
      background: rgba(25, 25, 25, 0.00);
      padding: 10px 16px;
      display: block;
      box-sizing: border-box;
      color: $black_text_color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Arial-400;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      outline: none;

      &.is_invalid {
        border-color: $error_color;
      }

      &:disabled {
        border-color: #f0f4ff;
        background: #f0f4ff;
      }

      &::placeholder {
        color: $black_text_color_50;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px
      }
    }

    .show_pass {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    input[type='password'] {
      font-family: Verdana, sans-serif;
    }
  }

  .link_text {
    color: $black_text_color;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $Arial-400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 257.143% */
    text-decoration-line: underline;
  }

  .cta {
    padding: 18px 24px;
    border-radius: 50px;
    background: $blue_color;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white_text_color;
    font-family: $Arial-400;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;

    &.m-0 {
      margin: 0;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
        background: $blue_color;
        color: $white_text_color;
        border: 1px solid transparent;

        svg {
          path {
            transition: all 0.3s ease-in-out;
            fill: #fff;
          }
        }
      }

    }

    .spinner {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }

    svg {
      display: block;
      margin-right: 12px;

      path {
        transition: all 0.3s ease-in-out;
      }
    }

    &:hover {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background: $white_text_color;
      color: $black_text_color;
      border-color: $blue_color;

      svg {
        path {
          transition: all 0.3s ease-in-out;
          fill: $black_text_color;
        }
      }
    }
  }

  .account {
    h3 {
      color: $black_text_color;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Arial-700;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }


  }
  .links {
    display: flex;
    margin: 0 -10px;
    justify-content: center;
    align-items: center;

    a {
      box-sizing: border-box;
      padding: 0 10px;
    }
  }
  .muted_text {
    color: $black_text_color_50;
    font-family: $Arial-400;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.tac {
      text-align: center;
    }

    margin: 0 0 20px;
  }

  .err-color {
    bottom: -12px;
    position: absolute;
    font-size: 12px;
    color: $error_color;
    font-family: $Arial-400;
    white-space: nowrap;

  }

  .is_invalid {
    border-color: $error_color;
  }

  .form-control:focus {
    box-shadow: none;
  }
}