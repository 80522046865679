.platforms_router {
  width: 100%;
  border-radius: 20px;
  border: 1px solid rgba(25, 25, 25, 0.12);
  background: #FFF;
  box-shadow: 0px 10px 40px 0px rgba(51, 102, 255, 0.08);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px;
  max-width: 920px;

  &_title {
    padding: 48px 40px 40px;
    color: #000;
    text-align: left;
    box-sizing: border-box;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $Arial-700;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
    @media all and (max-width: 767px) {
      padding: 32px 16px 32px;
    }
  }

  &_item {
    border-top: 1px solid rgba(25, 25, 25, 0.24);
    border-bottom: 1px solid rgba(25, 25, 25, 0.24);
    margin-bottom: 32px;
    position: relative;
    display: flex;
    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    &_left {
      width: 50%;
      padding: 20px 22px 20px 40px;
      align-items: flex-start;
      box-sizing: border-box;
      @media all and (max-width: 767px) {
        width: 100%;
        padding: 16px;
      }


      .left_data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        h3 {
          color: #191919;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-700;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin: 4px 0 6px;
        }

        p {
          color: #191919;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0 0 40px;
        }

        .buttons {
          display: flex;
          margin: 0 0 40px 0;

          button {
            .spinner {
              display: flex;
              justify-content: right;
              align-items: center;
              margin-right: 12px;
              width: 20px;
              height: 20px;
            }

            white-space: nowrap;
            display: flex;
            align-items: center;
            font-family: $Arial-400;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            color: $white_text_color;
            padding: 4px 24px;
            background: $blue_color;
            border-radius: 50px;
            border: 1px solid transparent;
            margin-right: 20px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:disabled {
              cursor: initial;
              opacity: 0.5;

              &:hover {
                color: $white_text_color;

                background: $blue_color;

                border-color: $blue_color;
              }
            }

            &:hover {
              transition: all 0.3s ease-in-out;
              background: #fff;
              border-color: $blue_color;
              color: #191919;
            }

            &.black {
              transition: all 0.3s ease-in-out;
              background: #191919;

              &:hover {
                transition: all 0.3s ease-in-out;
                background: #fff;
                border-color: #191919;
                color: #191919;
              }
            }
          }
        }

        .available {
          display: flex;
          align-items: center;

          &_title {
            color: $black_text_color_70;
            font-family: $Arial-400;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin: 0 14px 0 0;
          }

          &_list {
            display: flex;
            align-items: center;

            svg {
              display: block;
              margin-right: 12px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    &_img {
      width: 50%;
      @media all and (max-width: 767px) {
        width: 100%;
        max-height: 300px;
        padding: 0;
      }
      @media all and (max-width: 576px) {
        max-height: 200px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
  }

  .black_account_wrapper {
    padding: 0 40px 30px;
    @media all and (max-width: 767px) {
      padding: 0 16px 16px;
    }

    .black_account {
      background: $black_text_color;
      display: flex;
      border-radius: 20px;
      @media all and (max-width: 767px) {
        flex-direction: column;
      }

      &_left {
        flex: 2;
        display: flex;
        flex-direction: column;
        border-right: 1px solid rgba(255, 255, 255, 0.24);

        &_top {
          box-sizing: border-box;
          padding: 40px 0 0 30px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.24);
          overflow: hidden;
          @media all and (max-width: 767px) {
            padding: 32px 0 0 16px;
          }

          .features {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0 0 32px;
            padding-right: 40px;
            box-sizing: border-box;

            .title {
              display: flex;
              align-items: center;

              svg {
                display: block;
                margin-right: 18px;
              }

              &_data {
                display: flex;
                flex-direction: column;

                span {
                  &:first-child {
                    color: $white_text_color;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: $Arial-700;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px; /* 110% */
                    letter-spacing: -0.408px;
                    margin: 0 0 4px;
                    @media all and (max-width: 767px) {
                      font-size: 16px;
                    }
                  }

                  &:last-child {
                    color: $white_text_color_70;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: $Arial-400;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    @media all and (max-width: 767px) {
                      font-size: 14px;
                    }
                  }
                }
              }
            }

            .users {
              display: flex;

              svg {
                display: block;
                margin: 0 10px 0 0;
              }

              span {
                color: $white_text_color_70;
                text-align: right;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: $Arial-400;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }

          .data {
            display: flex;
            width: 100%;

            .period {
              display: flex;
              flex-direction: column;
              margin: 0 32px 20px 0;

              span {
                &:first-child {
                  color: $white_text_color_50;
                  font-feature-settings: 'clig' off, 'liga' off;
                  font-family: $Arial-400;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  margin: 0 0 10px;
                }

                &:last-child {
                  color: #2CBC63;
                  font-feature-settings: 'clig' off, 'liga' off;
                  font-family: $Arial-700;
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 22px; /* 68.75% */
                  letter-spacing: -0.408px;
                  @media all and (max-width: 767px) {
                    font-size: 22px;
                    line-height: 22px;
                  }
                }
              }
            }

            .chart {
              flex: 1;
              margin-right: -3px;

              svg {
                display: block;
                width: 100%;
                height: auto;
                object-fit: cover;;
              }
            }
          }
        }

        &_bottom {
          box-sizing: border-box;
          padding: 24px 30px 44px;
          @media all and (max-width: 767px) {
            padding: 16px 16px 32px;
          }
          @media all and (max-width: 576px) {
            padding: 16px;
          }

          .left_bottom_wrapper {
            margin: 0 -6px;
            display: flex;

            .left_bottom_item {
              display: flex;
              flex-direction: column;
              width: 33.3%;
              padding: 0 6px;

              .pnl {
                color: $white_text_color_50;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: $Arial-400;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin: 0 0 4px;
                @media all and (max-width: 767px) {
                  font-size: 14px;
                  margin: 0;
                }
                @media all and (max-width: 576px) {
                  font-size: 12px;
                }
              }

              .value {
                color: $white_text_color;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: $Arial-700;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                @media all and (max-width: 767px) {
                  font-size: 18px;
                }
                @media all and (max-width: 576px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      &_right {
        box-sizing: border-box;
        flex: 1;
        padding: 40px 0 44px 30px;
        display: flex;
        flex-direction: column;
        @media all and (max-width: 767px) {
          flex-direction: row;
          flex-wrap: wrap;
          padding: 16px 16px 32px;
        }

        &_top {
          border-bottom: 1px solid rgba(255, 255, 255, 0.24);
          padding: 0 0 22px 0;
          margin-bottom: 14px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          @media all and (max-width: 767px) {
            width: 50%;
            border-bottom: none;
            align-items: flex-start;

          }
          @media all and (max-width: 576px) {
            width: auto;
            margin: 0 10px 0 0;
          }

          img {
            margin: 0 14px 0 0;
            @media all and (max-width: 576px) {
              width: 34px;
              height: auto;
              margin: 0 10px 0 0;
            }
          }

          span {
            color: $white_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Arial-700;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            @media all and (max-width: 576px) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }

        &_list {
          display: flex;
          flex-direction: column;
          padding: 0 30px 0 30px;
          list-style: none;
          margin: 0 0 20px;
          box-sizing: border-box;
          @media all and (max-width: 767px) {
            width: 50%;
            padding: 0 0 0 30px;
          }
          @media all and (max-width: 576px) {
            flex: 1;
            width: auto;
            padding: 0 0 0 30px;
          }

          li {
            position: relative;
            color: $white_text_color;
            font-family: $Arial-400;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            @media all and (max-width: 576px) {
              line-height: 16px;
              margin-bottom: 4px;
            }

            &:before {
              content: '';
              width: 12px;
              height: 9px;
              position: absolute;
              left: -30px;
              top: 50%;
              transform: translateY(-50%);
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNC44MjY0MiIgeT0iOS45ODE0NSIgd2lkdGg9IjEuNjYyNDYiIGhlaWdodD0iMTEuNTQzNSIgcng9IjAuODMxMjI5IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTQwLjY1OSA0LjgyNjQyIDkuOTgxNDUpIiBmaWxsPSIjMkNCQzYzIi8+CjxyZWN0IHg9IjUuODc4NDIiIHk9IjguODI0MjIiIHdpZHRoPSIxLjY2MjQ2IiBoZWlnaHQ9IjYuNjQ5ODQiIHJ4PSIwLjgzMTIyOSIgdHJhbnNmb3JtPSJyb3RhdGUoMTM1IDUuODc4NDIgOC44MjQyMikiIGZpbGw9IiMyQ0JDNjMiLz4KPC9zdmc+Cg==");
              @media all and (max-width: 576px) {
                left: -20px;
              }

            }
          }
        }

        &_button {
          a {
            text-decoration: none;
          }

          margin: auto 0 0;
          padding-right: 30px;
          box-sizing: border-box;
          @media all and (max-width: 767px) {
            width: 100%;
            border-top: 1px solid rgba(255, 255, 255, 0.24);
            padding-top: 20px;
            padding-right: 0;
          }
        }

        button {
          display: flex;
          width: 100%;
          justify-content: center;
          font-family: $Arial-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          color: $white_text_color;
          padding: 4px 24px;
          background: $blue_color;
          border-radius: 50px;
          border: 1px solid transparent;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            transition: all 0.3s ease-in-out;
            background: #fff;
            border-color: $blue_color;
            color: #191919;
          }
        }
      }
    }
  }

  .platform_profile {
    padding: 0 40px 40px;
    @media all and (max-width: 767px) {
      padding: 0 16px 16px;

    }

    .edit_wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }

    .buttons {
      margin: 20px -5px 0;
      display: flex;

      button {
        margin: 0 5px;
      }
    }
  }
}